<template>
  <Tile z="1" class="Player" :key="player.name">
    <!--  v-bind:style="{background: 'linear-gradient(45deg,'+hexToRGB(player.color1)+','+hexToRGB(player.color2)+')'}" -->

    <div key="connected" v-if="isConnected" :title="player.name +' is connected to the game'">
      <i class="connectionStatus connected fad fa-wifi"/>
    </div>

    <div class="commanderModeButton" v-if="player.commanderList.length > 1" v-bind:class="{inverse: commanderMode===1}">
      <button class="z-index--1" @click="toggleCommanderMode()">
        <font-awesome-icon :icon="['fas', 'user-friends']" />
      </button>
    </div>


    <div key="disconnected" v-if="!isConnected" :title="player.name +' is disconnected from the game'">
      <i class="connectionStatus disconnected fad fa-wifi-slash"/>
    </div>

    <img
      v-if="player.commanderList[0] && player.commanderList[0].image_uri"
      class="commanderPic"
      v-bind:class="{show: commanderMode===0}"
      :src="player.commanderList[0].image_uri"/>

    <img
      v-if="player.commanderList[1] && player.commanderList[1].image_uri"
      class="commanderPic"
      v-bind:class="{show: commanderMode===1}"
      :src="player.commanderList[1].image_uri"/>

    <!--
    <div class="life" v-bind:style="{'borderColor': player.color2, background: 'radial-gradient('+player.color2+', '+player.color1+')'}">
    -->
    <div class="playerFace">
      <ProfilePic :icon="player.avatar"  v-bind:class="{activeEvent: activeEvent}"></ProfilePic>
      <!-- Life events -->
      <div class="event" v-if="activeEvent && activeEvent.type === 'life'">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'heart']" />
        </div>
        <div class="value font-style--readable">
           {{activeEvent.value > 0 ? '&plus;' : '&minus;'}}{{Math.abs(activeEvent.value)}}
        </div>
      </div>
      <!-- Commander Damage events -->
      <div class="event" v-if="activeEvent && activeEvent.type === 'commander_damage'">
        <div class="icon">
          <font-awesome-icon :icon="['fas', 'swords']" />
        </div>
        <div class="value font-style--readable">
           {{activeEvent.value > 0 ? '&plus;' : '&minus;'}}{{Math.abs(activeEvent.value)}}
        </div>
      </div>
      <!-- Counter events -->
      <div class="event" v-if="activeEvent && activeEvent.type === 'counter'">
        <div class="icon">
          <i v-if="activeEvent.extraInfo2.includes('ms')" :class="activeEvent.extraInfo2"></i>
          <font-awesome-icon v-if="activeEvent.extraInfo.includes('Commander_Tax')" :icon="['far', 'helmet-battle']" />
        </div>
        <div class="value font-style--readable">
           {{activeEvent.value > 0 ? '&plus;' : '&minus;'}}{{Math.abs(activeEvent.value)}}
        </div>
      </div>
      <span class="life font-style--readable">{{life}}</span>
      
    </div>
    <div class="name--container">
      <div class="name font-size--large">
        {{player.name}}
      </div>
      <div v-if="hasCommanders()">
        <div class="commanderLine">
          <div class="counter" title="Re-Cast Tax">
            <div class="counter--content font-style--readable">
              <font-awesome-icon class="counter--icon" :icon="['far', 'helmet-battle']" />{{player.counters['Commander_Tax_'+`${String(commanderMode)}`].value}}
            </div>
          </div>
          <span class="commanderName">{{player.commanderList[String(commanderMode)].name ? player.commanderList[String(commanderMode)].name : 'Commander'}}</span>
        </div>
      </div>
      <!-- The row of counters -->
      <div class="counters" v-if="getCommanderDamageForOpponents(player.publicId, String(commanderMode)).length > 0">
        <font-awesome-icon :icon="['fas', 'swords']" />
        &nbsp;
        <font-awesome-icon :icon="['fas', 'arrow-right']" />
        <div class="damageCounter" v-for="opponent in getCommanderDamageForOpponents(player.publicId, String(commanderMode))" :key="opponent.id">
          <ProfilePic :icon="opponent.avatar" size="tiny"></ProfilePic>
          <span  class="value font-style--readable">{{opponent.damage}}</span>
        </div>

        <!--
        <div class="counter  " :title="getCounterName(counter.name) + ' Counter'" v-for="counter in nonCommanderCounters" :key="counter.name">
          <i class="counter--icon" v-bind:class="counter.icon"></i>{{counter.value}}
        </div>
        -->
      </div>
    </div>
  </Tile>
</template>

<script>
import ProfilePic from "@/components/ProfilePic.vue";
import Tile from "@/components/Tile.vue";
export default {
  name: 'Player',
  components: {
    ProfilePic,
    Tile
  },
  data: () => {
    return {
      commanderMode: 0,
    }
  },
  props: ['player', 'life', 'game'],
  computed: {
    isConnected: function() { return this.player.isConnected },
    nonCommanderCounters: function() {
      let ret = Object.assign({}, this.player.counters);
      delete ret['Commander_Tax_0'];
      delete ret['Commander_Tax_1'];
      return ret;
    },

    activeEvent: function() {
      /*
      if(this.$store.state.player.newEventList.find(x => x.playerA === this.player.publicId)) {
        console.log(this.$store.state.player.newEventList.find(x => x.playerA === this.player.publicId));
      }*/
      return this.$store.state.player.newEventList.find(x => x.playerA === this.player.publicId);
    }
  },
  methods: {
    hasCommanders: function() {
      return this.game.format === 'brawl' || this.game.format === 'commander'
    },

    getCounterName: function(name) {
      return name.replace(/\w\S*/g, function(txt) {return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    },

    getCommanderDamageForOpponents: function(playerId, commanderNum) {
      let playerList = Object.keys(this.$store.state.game.game_obj.players);
      playerList = playerList.filter(x => x !== playerId);

      let damageList = [];
      for(let player of playerList) {
        player = this.$store.state.game.game_obj.players[player];

        const damageObj = {
          id: player.publicId,
          avatar: player.avatar,
          damage: this.$store.state.game.game_obj.players[playerId].commanderDamage[player.publicId + "_" + commanderNum].value
        };

        if(damageObj.damage > 0) {
          damageList.push(damageObj);
        }
        
      }

      return damageList;
    },

    toggleCommanderMode: function() {
      if(this.commanderMode === 0) {
        this.commanderMode = 1;
      } else {
        this.commanderMode = 0;
      }
    },

    hexToRGB: function(h) {
      let r = 0, g = 0, b = 0;

      // 3 digits
      if (h.length == 4) {
        r = "0x" + h[1] + h[1];
        g = "0x" + h[2] + h[2];
        b = "0x" + h[3] + h[3];

      // 6 digits
      } else if (h.length == 7) {
        r = "0x" + h[1] + h[2];
        g = "0x" + h[3] + h[4];
        b = "0x" + h[5] + h[6];
      }
      
      return "rgb("+ +r + "," + +g + "," + +b + ", 0.75)";
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.Player {

  .commanderPic {
    position: absolute;
    float: right;
    right: 0;

    width: 200px;
    height: 100%;
    
    object-fit: cover;
    object-position: 100% 10%;
    mask-image: linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,1) 35%);
    border-radius: 0 @corners--rounded @corners--rounded 0;

    transition: opacity @animation-duration--fast;
    opacity: 0.0;

    &.show {
      opacity: 1.0;
    }
  }


  box-sizing:border-box;
  position: relative;
  padding: 0px 10px 3px 10px;

  display: flex;
  align-items: center;
  flex-flow: row;
  font-size: 32px;
  min-height: 95px;
  

  color: white;

  .commanderModeButton {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;

    &.inverse {

      button {
        background: white;
        color:@app-color-primary-1;

      }
      
    }

    * {
      cursor: pointer;
    }
    
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: @app-color-primary-1;
      border: 0;
      padding: 10px;
      height: 35px;
      width: 35px;
      border-radius: 25px;
      transition: background @animation-duration--fast, color @animation-duration--fast;
    }
  }

  .playerFace {
    display: flex;
    flex-flow: column;
    position: relative;


    .ProfilePic {
      transition: opacity @animation-duration--fast linear;

      &.activeEvent {
        opacity: .5;
      }
    }
    .life {
      margin-top: 5px;
      font-size: .5em;
      font-weight: bold;
    }

    .event {
      position: absolute;
      height: 100%;
      width: 100%;

      font-size: .5em;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      box-sizing: border-box;
      padding-bottom: 24px;

      .icon {
        padding-bottom: 10px;
      }

      .value {
        position: absolute;
        top: 27px;
      }
    }
  }

  .name--container {
    display: flex;
    flex-flow: column;
    justify-content: left;
    height: 100%;
    margin-left: 10px;
    width: 100%;
    z-index: 2;
    & > * {
      text-shadow: 0px 3px 3px #000;
    }

    .name {
      display: flex;
      align-items: center;
      height: 40px;
      white-space: nowrap;
    }

    .commanderLine {
      text-align: left;
      font-size: .4em;
      height: 25px;
      display: flex;
      align-items: center;

      & > * {
        box-sizing: border-box;
        height: 25px;
      }

      .counter {
        margin-left: 0;
      }

      .commanderIcon {

      }

      .commanderName {
        margin-left: 2px;
        vertical-align: middle;
        line-height: 25px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media @tablet-down {
          max-width: 300px;
        }

        @media @phone-down {
          max-width: 175px;
        }
      }
    }

    .counters {
      display: flex;
      flex-flow: row;
      font-size: .4em;
      flex-wrap: wrap;
      margin-top: 2px;
      align-items: center;
    }

    .damageCounter {
      display: flex;
      flex-flow: row;

      height: 25px;
      width: 35px;
      margin-left: 5px;
      background: @background-color-primary;
      border-radius:20px;
      padding: 5px;
      box-sizing: border-box;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 0;
      }

      .value {
        margin-left: 3px;
      }
    }

    .counter {
      height: 25px;
      width: 35px;
      margin-left: 5px;
      background: @background-color-primary;
      border-radius:20px;
      padding: 5px;
      box-sizing: border-box;
      white-space: nowrap;
      display: flex;
      align-items: center;

      &:first-child {
        margin-left: 0;
      }
      
      &--icon {
        margin-right:3px;
        text-align: right;

        &::before {
          min-width: 15px;
          max-width: 15px;
        }
        
      }
    }
  }



  .connectionStatus {
    display: none;

    font-size: .75rem;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    background: white;
    padding: 5px;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    color: black;

    // Make this a reusable style
    box-shadow: 0px 3px 3px black;

    &.disconnected {
      opacity: .7;
    }
  }

}
</style>
